
import { Score } from '@/api/modules/scoreboard/scoreboardApi.interfaces';
import { useStore } from '@/store';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'GdgTable',
  props: {
    content: {
      type: Array as PropType<
        Array<{
          hover?: string;
          data: Record<string, { value: string | number | boolean; sortVal?: number }>;
          score: Score;
        }>
      >,
      default: Array,
    },
    excludedFields: {
      type: Array as PropType<Array<string>>,
      default: Array,
    },
    headers: {
      type: Array as PropType<Array<string>>,
      default: Array,
    },
    defaultSortField: {
      type: String,
      default: '',
    },
  },
  watch: {
    defaultSortField() {
      this.sortField = this.defaultSortField;
      this.sortAsc = true;
    },
    content() {
      this.sortField = this.defaultSortField;
      this.sortAsc = true;
    },
  },
  data: () => ({
    sortField: '',
    sortAsc: true,
    store: useStore(),
  }),
  mounted() {
    this.sortField = this.defaultSortField;
  },
  computed: {
    sortedContent() {
      return [...this.content].sort((a, b) => {
        let modifier = 1;
        if (!this.sortAsc) modifier = -1;
        if (
          a.data[this.sortField].sortVal !== undefined &&
          b.data[this.sortField].sortVal !== undefined
        ) {
          if (a.data[this.sortField].sortVal! < b.data[this.sortField].sortVal!)
            return -1 * modifier;
          if (a.data[this.sortField].sortVal! > b.data[this.sortField].sortVal!)
            return 1 * modifier;
        } else {
          if (a.data[this.sortField].value < b.data[this.sortField].value)
            return -1 * modifier;
          if (a.data[this.sortField].value > b.data[this.sortField].value)
            return 1 * modifier;
        }
        return 0;
      });
    },
  },
  methods: {
    formatHeader(fieldName: string) {
      return fieldName
        .replace(/_/g, ' ')
        .replace(/[A-Z]/g, (match) => {
          return ` ${match.toLowerCase()}`;
        })
        .trim();
    },
    sort(fieldName: string) {
      if (this.excludedFields.includes(fieldName)) return;

      if (fieldName === this.sortField) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortAsc = true;
      }
      this.sortField = fieldName;
    },
    showDetailsModal(score: Score) {
      this.store.dispatch('ui/openOverlay', {
        type: 'modal',
        id: '1234',
        closeButton: true,
        component: 'ScoreDisplay',
        componentAttr: {
          score,
        },
      });
    },
  },
});
