import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "gdg-score flex col" }
const _hoisted_2 = { class: "flex row gdg-s-h" }
const _hoisted_3 = { class: "gdg-s-user" }
const _hoisted_4 = { class: "gdg-s-time" }
const _hoisted_5 = { class: "gdg-s-msg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.score?.user), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.time), 1)
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.score?.source), 1)
  ]))
}