
import { DrawerConfig } from '@/store/modules/ui/ui.interfaces';
import { defineComponent, PropType } from 'vue';
import Icon from '@/components/atoms/Icon.vue';

export default defineComponent({
  name: 'QaDrawer',
  components: { Icon },
  props: {
    data: {
      type: Object as PropType<DrawerConfig>,
      default: Object,
    },
  },
  computed: {
    computedEdge(): string {
      if (typeof this.data?.edge === 'string') {
        return this.data.edge;
      }
      let edge = this.data?.edge?.md;
      if ((this.$mqs.sm || this.$mqs.xs) && this.data?.edge?.sm) {
        edge = this.data?.edge?.sm;
      }
      if (this.$mqs.xs && this.data?.edge?.xs) {
        edge = this.data?.edge?.xs;
      }
      return edge || '';
    },
  },
});
