
import { Score } from '@/api/modules/scoreboard/scoreboardApi.interfaces';
import { defineComponent, PropType } from 'vue';
import GdgTable from '@/components/atoms/Table.vue';
import Icon from '@/components/atoms/Icon.vue';
import dayjs from '@/utils/dayjs';

function transformScoreInfo(info: Score['info']) {
  const obj: Record<string, { value: string | number | boolean; sortVal?: number }> = {};
  for (const key in info) {
    const tKey = key as keyof Score['info'];
    switch (key) {
      case 'emoji':
      case 'date':
      case 'number':
        break;
      case 'score':
        if (isNaN(Number(info[tKey]))) {
          obj[tKey] = { value: info[tKey], sortVal: Infinity };
        } else {
          obj[tKey] = { value: info[tKey], sortVal: info[tKey] };
        }
        break;
      case 'time': {
        const d = dayjs.duration(info[tKey]);
        obj[tKey] = { value: d.format('m[m] s[s]'), sortVal: d.asMilliseconds() };
        break;
      }
      case 'hardMode':
        if (info[tKey]) {
          obj[tKey] = { value: '✅', sortVal: 0 };
        } else {
          obj[tKey] = { value: '❎', sortVal: 1 };
        }
        break;
      case 'hints':
        obj[tKey] = { value: info[tKey] ? info[tKey] : 0 };
        break;
      default:
        obj[tKey] = { value: info[tKey] };
        break;
    }
  }
  return obj;
}

export default defineComponent({
  name: 'GdgScoreboard',
  components: {
    GdgTable,
    Icon,
  },
  props: {
    first: {
      type: Boolean,
      default: false,
    },
    last: {
      type: Boolean,
      default: false,
    },
    scores: {
      type: Array as PropType<Array<Score>>,
      default: Array,
    },
    game: {
      type: String,
      default: '',
    },
  },
  emits: {
    next: null,
    prev: null,
  },
  computed: {
    defaultSortField() {
      const fields = this.tableContent.reduce((arr, tc) => {
        arr.push(...Object.keys(tc.data));
        return arr;
      }, [] as string[]);
      if (fields.includes('score')) {
        return 'score';
      } else if (fields.includes('time')) {
        return 'time';
      }
      return fields[0];
    },
    tableContent() {
      return this.scores.map((s) => ({
        hover: s.source,
        data: {
          user: {
            value: s.user,
          },
          ...transformScoreInfo(s.info),
          timeOfDay: {
            value: dayjs.unix(s.timestamp).format('h:mm a'),
            sortVal: s.timestamp,
          },
        },
        score: s,
      }));
    },
    tableHeaders() {
      return (
        (this.tableContent.length > 0 &&
          Object.keys(this.tableContent[0].data)
            .filter((h) => !([] as string[]).includes(h))
            .sort((a, b) => {
              const specialHeaders = ['user', 'score', 'time'];
              const aIndex = specialHeaders.indexOf(a);
              const bIndex = specialHeaders.indexOf(b);

              if (aIndex > -1 && bIndex > -1) {
                return aIndex - bIndex;
              } else if (aIndex > -1) {
                return -1;
              } else if (bIndex > -1) {
                return 1;
              }
              return 0;
            })) ||
        []
      );
    },
  },
});
