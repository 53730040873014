import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "gdg-scoreboard" }
const _hoisted_2 = { class: "flex row align-c gdg-s-head" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "gdg-s-title" }
const _hoisted_5 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_GdgTable = _resolveComponent("GdgTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.first)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "gdg-s-prev clear-btn",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('prev')), ["prevent"]))
          }, [
            _createVNode(_component_Icon, { name: "chevron_left" })
          ]))
        : (_openBlock(), _createElementBlock("span", _hoisted_3)),
      _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.game), 1),
      (!_ctx.last)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: "gdg-s-next clear-btn",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('next')), ["prevent"]))
          }, [
            _createVNode(_component_Icon, { name: "chevron_right" })
          ]))
        : (_openBlock(), _createElementBlock("span", _hoisted_5))
    ]),
    _createVNode(_component_GdgTable, {
      class: "gdg-s-score-table",
      content: _ctx.tableContent,
      headers: _ctx.tableHeaders,
      "default-sort-field": _ctx.defaultSortField
    }, null, 8, ["content", "headers", "default-sort-field"]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}