
import { defineComponent } from 'vue';
import Overlays from '@/components/structure/Overlays.vue';

export default defineComponent({
  name: 'GdgApp',
  components: {
    Overlays,
  },
});
