
import { useStore } from '@/store';
import { defineComponent } from 'vue';
import Scoreboard from '@/components/molecules/Scoreboard.vue';
import Icon from '@/components/atoms/Icon.vue';
import dayjs from '@/utils/dayjs';
import Spinner from '@/components/atoms/Spinner.vue';

export default defineComponent({
  name: 'GdgHome',
  components: { Scoreboard, Icon, Spinner },
  mounted() {
    this.fetchScoreboard();
  },
  data: () => ({
    today: dayjs().startOf('day'),
    store: useStore(),
  }),
  methods: {
    async selectDate(offset: number) {
      await this.store.dispatch('selectOtherDate', offset);
      this.updateRoute();
    },
    async updateRoute() {
      this.$router.push({ query: { date: this.date.format('YYYY-MM-DD') } });
    },
    selectOtherGame(offset: number) {
      this.store.dispatch('selectOtherGame', offset);
    },
    async fetchScoreboard(forceRefresh?: boolean) {
      await this.store.dispatch('fetchScoreboard', {
        date:
          (this.$route.query?.date as string | undefined) ||
          this.date.format('YYYY-MM-DD'),
        forceRefresh,
      });
    },
  },
  computed: {
    date() {
      const selectedDate = this.store.state.selectedDate;
      if (selectedDate) {
        return dayjs(selectedDate, 'YYYY-MM-DD');
      }
      return this.today.clone();
    },
    scoreboardCallStatus() {
      return this.store.state.scoreboardCallStatus;
    },
    gameIndex() {
      return this.store.getters['gameIndex'];
    },
    selectedGame: {
      get() {
        return this.store.state.selectedGame;
      },
      set(game: string) {
        this.store.commit('setGame', game);
      },
    },
    first() {
      return this.store.getters['dateEdges'].first;
    },
    last() {
      return this.store.getters['dateEdges'].last;
    },
    dateDisplay() {
      return this.date.format('MMMM D, YYYY');
    },
    scoreboard() {
      const store = useStore();
      return store.state.scoreboard;
    },
    games() {
      return this.store.getters['games'];
    },
    selectedGameScoreboard() {
      return this.store.getters['selectedGame'];
    },
  },
});
