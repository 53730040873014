
import { Score } from '@/api/modules/scoreboard/scoreboardApi.interfaces';
import dayjs from '@/utils/dayjs';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'GdgScore',
  components: {},
  props: {
    score: {
      type: Object as PropType<Score>,
      required: true,
    },
  },
  computed: {
    time() {
      return dayjs.unix(this.score.timestamp).format('h:mm a, MMM D');
    },
  },
});
