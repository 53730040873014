
import { ModalConfig } from '@/store/modules/ui/ui.interfaces';
import { defineComponent, PropType } from 'vue';
import Icon from '@/components/atoms/Icon.vue';
import ScoreDisplay from '@/components/molecules/ScoreDisplay.vue';

export default defineComponent({
  name: 'GdgModal',
  components: { Icon, ScoreDisplay },
  props: {
    data: {
      type: Object as PropType<ModalConfig>,
      default: Object,
    },
  },
  setup(props, ctx) {
    function primary() {
      ctx.emit('close', props.data?.id);
      ctx.emit('primary');
    }
    function secondary() {
      ctx.emit('close', props.data?.id);
      ctx.emit('secondary');
    }

    return {
      primary,
      secondary,
    };
  },
});
