import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "gdg-home" }
const _hoisted_2 = { class: "flex row align-c gdg-h-header" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "gdg-s-title" }
const _hoisted_5 = { class: "flex row" }
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Scoreboard = _resolveComponent("Scoreboard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "gdg-h-prev clear-btn",
        disabled: _ctx.first,
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.selectDate(-1)), ["prevent"]))
      }, [
        _createVNode(_component_Icon, { name: "chevron_left" })
      ], 8, _hoisted_3),
      _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.dateDisplay), 1),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.last || _ctx.date.isSame(_ctx.today, 'day'))
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "gdg-h-refresh clear-btn",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.fetchScoreboard(true)), ["prevent"]))
            }, [
              _createVNode(_component_Icon, { name: "refresh" })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          class: "gdg-h-next clear-btn",
          disabled: _ctx.last,
          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.selectDate(1)), ["prevent"]))
        }, [
          _createVNode(_component_Icon, { name: "chevron_right" })
        ], 8, _hoisted_6)
      ])
    ]),
    _createVNode(_component_Scoreboard, {
      class: "relative",
      game: _ctx.selectedGame,
      first: _ctx.gameIndex <= 0,
      last: _ctx.gameIndex === _ctx.games?.length - 1,
      scores: _ctx.selectedGameScoreboard,
      onNext: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectOtherGame(1))),
      onPrev: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectOtherGame(-1)))
    }, {
      default: _withCtx(() => [
        (_ctx.scoreboardCallStatus === 'AWAITING')
          ? (_openBlock(), _createBlock(_component_Spinner, {
              key: 0,
              class: "absolute-center"
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["game", "first", "last", "scores"])
  ]))
}