import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "gdg-modal" }
const _hoisted_2 = {
  key: 1,
  class: "gdg-modal--default"
}
const _hoisted_3 = {
  key: 0,
  class: "gdg-modal--title"
}
const _hoisted_4 = {
  key: 1,
  class: "gdg-modal--body"
}
const _hoisted_5 = { class: "gdg-modal--button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "gdg-modal--bg cursor--pointer",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (!_ctx.data?.ignoreBgClick ? _ctx.$emit('close', _ctx.data?.id) : () => {}), ["prevent"]))
    }),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.data?.component)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.data?.component), _mergeProps({ key: 0 }, _ctx.data?.componentAttr, _toHandlers(_ctx.data?.componentListeners || {}), {
            class: _ctx.data?.componentClasses,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close', _ctx.data?.id)))
          }), null, 16, ["class"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.data?.title)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.data?.title), 1))
              : _createCommentVNode("", true),
            (_ctx.data?.body)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.data?.body), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.data?.mainButton)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "gdg-modal--button",
                    onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.primary && _ctx.primary(...args)), ["prevent"]))
                  }, _toDisplayString(_ctx.data?.mainButton), 1))
                : _createCommentVNode("", true),
              (_ctx.data?.secondaryButton)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "gdg-modal--button",
                    onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.secondary && _ctx.secondary(...args)), ["prevent"])),
                    light: ""
                  }, _toDisplayString(_ctx.data?.secondaryButton), 1))
                : _createCommentVNode("", true)
            ])
          ])),
      (_ctx.data?.closeButton)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: "gdg-close-button clear-btn cursor--pointer",
            onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.$emit('close', _ctx.data?.id)), ["prevent"]))
          }, [
            _createVNode(_component_Icon, { name: "close" })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}