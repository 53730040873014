import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["title", "onClick"]
const _hoisted_3 = {
  key: 0,
  class: "gdg-t-h-line"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "gdg-flex-table",
    style: _normalizeStyle({
      gridTemplateColumns: `repeat(${_ctx.headers.length}, auto)`,
    })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `${header}_${i}`,
        onClick: _withModifiers(($event: any) => (_ctx.sort(header)), ["prevent"]),
        class: _normalizeClass(["gdg-t-head", {
        'text-center': i > 0 && i < _ctx.headers.length - 1,
        'text-left': i === 0,
        'text-right': i === _ctx.headers.length - 1,
      }])
      }, _toDisplayString(_ctx.formatHeader(header)), 11, _hoisted_1))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedContent, (r, i) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: `row_${i}`
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (col, j) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `row_${i}_${col}_${j}`,
            class: _normalizeClass(["gdg-t-col", {
          'text-center': j > 0 && j < _ctx.headers.length - 1,
          'text-left': j === 0,
          'text-right': j === _ctx.headers.length - 1,
        }]),
            title: r.hover,
            onClick: _withModifiers(($event: any) => (_ctx.showDetailsModal(r.score)), ["prevent"])
          }, _toDisplayString(r.data[col].value), 11, _hoisted_2))
        }), 128)),
        (i < _ctx.sortedContent.length - 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ], 4))
}