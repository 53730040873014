
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GdgIcon',
  props: {
    name: {
      type: String,
      default: '',
    },
  },
});
