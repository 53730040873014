import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, toHandlers as _toHandlers, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "gdg-overlays" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FullscreenOverlay = _resolveComponent("FullscreenOverlay")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_Drawer = _resolveComponent("Drawer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, { name: "fade" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fullscreens, (fullscreen) => {
          return (_openBlock(), _createBlock(_component_FullscreenOverlay, {
            key: fullscreen.id,
            data: fullscreen,
            onClose: _ctx.closeOverlay
          }, null, 8, ["data", "onClose"]))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_TransitionGroup, { name: "fade" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modals, (modal) => {
          return (_openBlock(), _createBlock(_component_Modal, _mergeProps({
            key: modal.id,
            data: modal,
            onClose: _ctx.closeOverlay
          }, _toHandlers(modal.modalListeners || {})), null, 16, ["data", "onClose"]))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_TransitionGroup, {
      name: "drawer-slide",
      duration: 200
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.drawers, (drawer) => {
          return (_openBlock(), _createBlock(_component_Drawer, {
            key: drawer.id,
            data: drawer,
            onClose: _ctx.closeOverlay
          }, null, 8, ["data", "onClose"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}